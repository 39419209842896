import { i18n } from "@/plugins/i18n";
import { FormField, FormFieldTypes } from "helix-vue-components";

export const doctorForm: FormField[] = [
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.first_name"),
    name: "first_name",
    options: {
      validationRules: ["required"],
      row: 1,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.middle_name"),
    name: "middle_name",
    options: {
      row: 1,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.last_name"),
    name: "last_name",
    options: {
      validationRules: ["required"],
      row: 1,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.title_field"),
    name: "title",
    options: {
      row: 1,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.address"),
    name: "address",
    options: {
      row: 2,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.apt_suite"),
    name: "apt_suite",
    options: {
      row: 2,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.city"),
    name: "city",
    options: {
      row: 2,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.state"),
    name: "state",
    options: {
      row: 2,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.zip_code"),
    name: "zip_code",
    options: {
      row: 3,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.email"),
    name: "email",
    options: {
      validationRules: ["email"],
      row: 3,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.phone_number"),
    name: "phone_number",
    options: {
      row: 3,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.fax_number"),
    name: "fax_number",
    options: {
      row: 3,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.license"),
    name: "license",
    options: {
      validationRules: ["required"],
      row: 4,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.dea_number"),
    name: "dea_number",
    options: {
      row: 4,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.website"),
    name: "website",
    options: {
      row: 4,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("doctors.national_provider_identifier"),
    name: "national_provider_identifier",
    options: {
      row: 4,
      flex: 3
    }
  },
  {
    component: FormFieldTypes.radioGroup,
    label: i18n.t("doctors.license_active"),
    name: "active_license",
    defaultValue: 1,
    options: {
      vuetifyProps: {
        row: true
      },
      groupOptions: [
        {
          text: i18n.t("doctors.license_active_yes"),
          value: 1
        },
        {
          text: i18n.t("doctors.license_active_no"),
          value: 0
        }
      ]
    }
  }
];
