export interface Doctor {
  account_id?: number | null;
  address?: string | null;
  apt_suite?: string | null;
  avatar_content_type?: string | null;
  avatar_file_name?: string | null;
  avatar_file_size?: number | null;
  avatar_medium_url?: string | null;
  avatar_original_url?: string | null;
  avatar_thumb_url?: string | null;
  avatar_updated_at?: string | null;
  avatar_variants?: string | null;
  birthday?: string | null;
  cellphone?: string | null;
  city?: string | null;
  country?: string | null;
  customer_id?: string | null;
  email?: string | null;
  first_name?: string | null;
  flat_discount_rate?: number | null;
  gender?: string | null;
  id?: number | null;
  id_expiration?: string | null;
  id_is_temporary?: number | null;
  id_number?: string | null;
  id_type?: string | null;
  last_name?: string | null;
  location_owner_id?: number | null;
  member_level_id?: number | null;
  middle_name?: string | null;
  phone?: string | null;
  shows_checkin_note?: number | null;
  state?: string | null;
  status?: string | null;
  status_at?: string | null;
  tax_exempt?: number | null;
  type?: string | null;
  uuid?: string | null;
  zip?: string | null;
  fax?: string | null;
  dea?: string | null;
  website?: string | null;
  license?: string;
  title?: string;
  national_provider_identifier?: number | null;
  active_license?: number | null;
  name?: string | null;
  dea_number?: string | null;
  license_number?: string | null;
}

export interface DoctorTable {
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
  national_provider_identifier: string;
  id: number | null;
}

export const defaultDoctor: Doctor = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  phone: "",
  title: "",
  national_provider_identifier: null,
  active_license: 1,
  fax: "",
  zip: "",
  dea: "",
  website: "",
  address: "",
  apt_suite: "",
  city: "",
  state: ""
};
