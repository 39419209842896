import { policyList } from "@/enums/permissions";
import { defaultDoctor, Doctor } from "@/interfaces/doctor";
import { RetailSettings } from "@/interfaces/retailSettings";
import { doctorForm } from "@/metadata/doctorForm";
import { doctorService } from "@/services/doctor.service";
import { BooleanCheck, PageNavAction } from "@/types/types";
import {
  DynamicFormComponent,
  FormField,
  FormResponse
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./DoctorsForm.template.vue";

@Component({
  components: {
    DynamicFormComponent
  },
  mixins: [Template]
})
export default class DoctorsFormComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  /**
   * fields to render
   */
  public fieldsConfig: FormField[] = doctorForm;
  public form!: DynamicFormComponent;
  public model: Doctor = { ...defaultDoctor };

  /**
   * @method to save
   */
  public tabActive = "";
  public tabsErrors = {
    basicInfo: false
  };
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public isLoading = false;

  public conditionallyMandatoryFields: string[] = [
    "address",
    "national_provider_identifier",
    "dea_number"
  ];

  public navigateAction(docId: string) {
    if (this.$route.query.custId) {
      this.$router.push({
        name: "customers-edit",
        params: { id: String(this.$route.query.custId) },
        query: { newDocId: docId }
      });
    } else {
      this.$router.back();
    }
  }

  public async save() {
    const formData: FormResponse = await this.form.submit();
    if (formData.valid) {
      const response = await doctorService.saveNewDoctorProfile(formData);
      if (response) {
        this.navigateAction(response.id);
      }
    }
  }

  public cancel() {
    this.navigateAction("noDoctorAdded");
  }

  public async update() {
    const formData: FormResponse = await this.form.submit();
    if (formData.valid) {
      const response = await doctorService.updateDoctor(formData);
      if (response) {
        this.$router.back();
      }
    }
  }

  protected async created() {
    if (this.retailSettings.pharmacy_mode_enabled) {
      this.fieldsConfig = doctorForm.map(field => {
        if (this.conditionallyMandatoryFields.includes(field.name || "")) {
          return {
            ...field,
            options: { ...field.options, validationRules: ["required"] }
          };
        } else {
          return field;
        }
      });
    }
  }

  protected async mounted() {
    this.form = this.$refs["doctor-form"] as DynamicFormComponent;
    this.setPageNav({
      title: "doctors.title",
      isLoading: () => this.isLoading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            visibleCondition: () => {
              if (this.$route.name === "doctors-edit") {
                return this.hasPermission(policyList.modifyDoctors);
              } else if (this.$route.name === "doctors-add") {
                return this.hasPermission(policyList.createDoctors);
              } else {
                return false;
              }
            },
            action:
              this.$route.name === "doctors-edit" ? this.update : this.save,
            vuetifyProps: () => ({
              loading: this.isLoading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isLoading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    if (this.$route.name === "doctors-edit") {
      const doctor = await doctorService.getSpecificDoctor(
        this.$route.params.id
      );
      this.model = doctor;
    }
  }
}
