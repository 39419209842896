import { Doctor } from "@/interfaces/doctor";
import { HttpQuery } from "@/interfaces/httpQuery";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { FormResponse } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { messagesService } from "./messages.service";

class DoctorService extends HttpService {
  public async saveNewDoctorProfile(formResponse: FormResponse) {
    try {
      this.uri = "store/doctors";
      const payload = cloneDeep(formResponse!.currentModel);
      const response = await super.post(payload);
      messagesService.renderSuccessMessage(i18n.t("doctor_created").toString());
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getDoctors(
    page = 1,
    perPage = 10,
    status = "ACTIVE",
    searchTerm = "",
    sortBy = "updated_at",
    descending = true
  ) {
    try {
      this.uri = `store/doctors`;
      const query: HttpQuery = {
        page,
        sort: `${descending ? "-" : ""}${sortBy}`,
        per_page: perPage,
        "q[first_name_or_last_name_or_middle_name_contains]": searchTerm
      };
      query[`q[is_active${status === "ACTIVE" ? "" : "_is_false"}]`] = 1;
      const response = await super.get(query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getSpecificDoctor(id: string) {
    try {
      this.uri = `store/doctors`;
      const response = await super.findById(id, this.query);
      return { ...response, id: response.id };
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async updateDoctor(formResponse: FormResponse) {
    try {
      const payload = cloneDeep(formResponse!.currentModel);
      payload!.id_is_temporary = false;
      this.uri = `store/doctors`;
      const response = await super.put(payload, payload, true);
      messagesService.renderSuccessMessage(i18n.t("doctor_updated").toString());
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async toggleDoctorState(action: string, id: number) {
    try {
      this.uri = `store/doctors/${id}/${action}`;
      const response = await super.patch(null);
      messagesService.renderSuccessMessage(
        i18n.t("doctor_status_changed").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async toggleDoctorStateMultiple(action: string, payload: number[]) {
    try {
      this.uri = `store/doctors/${action}`;
      const response = await super.patch(payload);
      messagesService.renderSuccessMessage(
        i18n.t("doctor_status_changed").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
}

export const doctorService = new DoctorService();
