import { render, staticRenderFns } from "./DoctorsForm.template.vue?vue&type=template&id=df4aef38&scoped=true&"
var script = {}
import style0 from "./DoctorsForm.template.vue?vue&type=style&index=0&id=df4aef38&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df4aef38",
  null
  
)

export default component.exports